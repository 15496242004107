import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { rankingActions } from '../../actions';
import { UsersPointsTable } from '../Tables';
import { SkeletonTable } from '../Skeletons';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

export function RankingDetailsModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openDetailsModal } = useSelector(state => state.ranking);
  const { loading } = useSelector(state => state.wallet);

  const toogleCloseDetailsModal = useCallback(() => {
    dispatch(rankingActions.setOpenDetailsModal(false, null));
  }, []);

  return (
    <Dialog open={openDetailsModal} onClose={toogleCloseDetailsModal} maxWidth="lg">
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h3">Detalhes</Typography>
        <IconButton onClick={toogleCloseDetailsModal}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {loading ? <SkeletonTable cols={4} /> : <UsersPointsTable />}
      </DialogContent>
    </Dialog>
  );
}
